<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>国际招标与采购信息</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in fenleiList" :key="index" @click="tabClick(item.id)">
                        <div :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right" v-loading="listloading">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/zbcg?hzy_id=5'}">外贸营销平台工具</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item :to="{path: '/zbcg?hzy_id=5'}">国际招标与采购信息</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="xinxi-wrap">
                        <div class="xinxi" v-for="(item,index) in liebiaoList" :key="index">
                            <div class="xinxi-img"><img :src="item.banner_url" alt=""></div>
                            <div class="xinxi-text">{{item.name}}</div>
                            <div class="xinxi-p" v-html="delLabel(item.content)"></div>
                            <div class="xinxi-bt"  @click="goTo('msgs_details',{id:item.id})">查看详情</div>                     
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getplates,getplatexts} from '../../../api/index'
    export default {
        data() {
            return {
                listloading:false,
                id:'',
                fenleiList:[],
                liebiaoList:[]                
            }
        },
        created() {
            this.id=this.$route.query.id  
            this.getplates()
            this.getplatexts()  
        },
        methods: {    
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            tabClick(id){
                this.id=id;
                this.getplatexts();
            },
            //获取分类
            getplates(){
                getplates({
                    p_id:5
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.fenleiList=res.data
                    }
                })
            },  
            //获取列表
            getplatexts(){
                this.listloading=true;
                getplatexts({
                    plate_id:this.id
                }).then((res)=>{
                    setTimeout(() => {
                        this.listloading=false;
                    }, 200);                    
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList=res.data.data
                    }
                })

            },
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 30px 0;
                .xinxi-wrap{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    padding: 0 10px;
                    .xinxi{
                        box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.06);border-radius: 5px;
                        width: 23%; margin: 15px 1%;
                        .xinxi-img{width:100%;height:150px;
                            img{width: 100%;height: 100%;object-fit: cover;}
                        }
                        .xinxi-text{color: #2E2E2E;font-size: 20px;font-weight: bold;padding: 17px 20px;}
                        .xinxi-p{color: #666666;font-size: 15px;font-weight: 300;padding: 0 20px;display: -webkit-box;
                            -webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;line-height: 26px;min-height: 75px;}
                        .xinxi-bt{
                            width: 80%;height: 45px;line-height: 45px;text-align: center;color: #fff;font-size: 16px;cursor: pointer;
                            background: linear-gradient(90deg, #FF820C, #FFB053);border-radius: 22px;margin: auto;margin-top: 25px;margin-bottom: 20px;
                        }
                    }                    
                    
                }                
            }
        }
        
    }

</style>